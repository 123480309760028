var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-filters filters-container filters table"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.showLePicker ? _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "le-picker"
  }, [_c('sygni-select', {
    attrs: {
      "label": _vm.isPortfolioCompany ? 'COMPANY' : 'FUND',
      "options": _vm.options,
      "value": _vm.viewedId
    },
    on: {
      "input": _vm.setViewedId
    }
  })], 1)]) : _vm._e(), _c('div', {
    class: _vm.showLePicker ? 'col-2' : 'col-4'
  }, [_c('sygni-input', {
    attrs: {
      "label": 'COMPANY NAME'
    },
    model: {
      value: _vm.filters.code.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.code, "value", $$v);
      },
      expression: "filters.code.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'TRANSACTION DATE',
      "range": true
    },
    model: {
      value: _vm.filters.date.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.date, "value", $$v);
      },
      expression: "filters.date.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-select', {
    attrs: {
      "options": [{
        label: 'Registered',
        value: 'REGISTERED'
      }, {
        label: 'Unknown',
        value: 'UNKNOWN'
      }, {
        label: 'Unregistered',
        value: 'UNREGISTERED'
      }],
      "label": 'REGISTRATION STATUS'
    },
    model: {
      value: _vm.filters.registrationStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.registrationStatus, "value", $$v);
      },
      expression: "filters.registrationStatus.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'REGISTRATION DATE',
      "range": true
    },
    model: {
      value: _vm.filters.registrationDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.registrationDate, "value", $$v);
      },
      expression: "filters.registrationDate.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'CREATED',
      "range": true
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }, [_c('sygni-date-picker', {
    staticClass: "secondary border-collapse",
    attrs: {
      "label": 'UPDATED',
      "range": true
    },
    model: {
      value: _vm.filters.updatedAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.updatedAt, "value", $$v);
      },
      expression: "filters.updatedAt.value"
    }
  })], 1), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "filters__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled black",
    attrs: {
      "loading": _vm.isLoading,
      "selectable": false
    },
    on: {
      "click": function click($event) {
        return _vm.applyFilters();
      }
    }
  }, [_vm._v(" Show results (" + _vm._s(_vm.itemsCount) + ")")]), _c('div', {
    staticClass: "clear-all-button",
    on: {
      "click": function click($event) {
        return _vm.clearAll();
      }
    }
  }, [_vm._v("Clear all")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }