var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "transactions-table"
  }, [_c('div', {
    staticClass: "transactions-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "transactionsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "row-clicked": _vm.onRowClicked,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "company text-left"
        }, [_c('span', [_vm._v(_vm._s(rowData.item.code))])])];
      }
    }, {
      key: "cell(date)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.date)) + " | " + _vm._s(_vm._f("dateGetTime")(rowData.item.date)))])];
      }
    }, {
      key: "cell(registrationDate)",
      fn: function fn(rowData) {
        return [!rowData.item.registrationDate ? _c('span') : _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.registrationDate)) + " | " + _vm._s(_vm._f("dateGetTime")(rowData.item.registrationDate)))])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.createdAt)) + " | " + _vm._s(_vm._f("dateGetTime")(rowData.item.createdAt)))])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4 text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.createdAt)) + " | " + _vm._s(_vm._f("dateGetTime")(rowData.item.createdAt)))]), _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.item._showDetails
          }
        })], 1)])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }